<template>
  <div>
    <div class="grid grid-cols-2">
      <div class="header-text pb-3" data-cy="header-admin-mentor">
        Mentor Records
      </div>

      <div v-if="isAdm() || isSra()" class="text-right">
        <router-link to="/admin/mentors/add">
          <Button text="Add" />
        </router-link>
      </div>
    </div>
    <div class="grid gap-8 pb-5">
      <div class="box-style">
        <div class="secondhead-text">Filters</div>
        <div class="grid grid-cols-3 gap-3 mb-3">
          <div>
            <div class="pb-2"><label for="dropdown-status">Status</label></div>
            <select
              id="dropdown-status"
              class="text-sm p-1 pl-2 border-steel-gray rounded h-8 w-full"
              v-model="mentorStatus"
            >
              <option value="">All</option>
              <option
                v-for="status in lookup_mentor_status_options"
                :key="status"
                :value="status"
              >
                {{ status }}
              </option>
            </select>
          </div>
          <div>
            <div class="pb-2">
              <label for="dropdown-practice-areas">Practice Areas</label>
            </div>

            <select
              id="dropdown-practice-areas"
              class="text-sm p-1 pl-2 border-steel-gray rounded h-8 w-full"
              v-model="practiceAreaId"
            >
              <option value="">All</option>
              <option
                v-for="practiceArea in lookup_practice_areas"
                :key="practiceArea.practice_area_id"
                :value="practiceArea.practice_area_id"
              >
                {{ practiceArea.description }}
              </option>
            </select>
          </div>
          <div class="">
            <div class="pb-2">
              <label for="autocomplete-employer">Employer</label>
            </div>
            <Multiselect
              id="autocomplete-employer"
              v-model="employerId"
              value="null"
              ref="multiselect"
              noOptionsText=""
              @focusout="searchEmployers(false)"
              :close-on-select="true"
              :minChars="2"
              :delay="0"
              :searchable="true"
              :classes="classes"
              :options="searchEmployers"
            >
            </Multiselect>
          </div>
        </div>
        <div class="mb-3">
          <div class="mb-2">
            <label for="textbox-filter">Name</label>
          </div>
          <input
            id="textbox-filter"
            type="text"
            v-model.trim="filterText"
            class="border-steel-gray rounded h-8 w-full disabled:bg-light-gray"
            :disabled="!loaded"
          />
        </div>
        <div class="profile_box flex justify-end" v-if="filtered">
          <Button text="Reset Filters" :primary="false" @click="reset" />
        </div>
      </div>
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <div class="box-style overflow-auto" v-if="mentors">
          <dynamic-table
            :records="this.mentors"
            :fields="tableConfig"
            :paginationPage="paginationPage"
            :pagination-total-items="paginationTotalItems"
            pagination
            @pageChanged="nextPage($event)"
            no-data-msg="No mentors to display for the current filter"
            striped
            hover
          >
            <template #cell(open-mentor)="{ item }">
              <router-link :to="'./mentors/view/' + item.mentor_id">
                <font-awesome-icon
                  icon="plus"
                  class="text-purple"
                  aria-label="view mentor"
                />
              </router-link>
            </template>

            <template #cell(email)="{ item }">
              <td>{{ item?.email[0]?.email_address }}</td>
            </template>
            <template #cell(phone)="{ item }">
              <td>{{ displayPhoneNumber(item?.phone) }}</td>
            </template>

            <template #cell(edit)="{ item }">
              <div v-if="isAdm() || isSra()" class="text-center w-100">
                <router-link :to="'./mentors/edit/' + item.mentor_id">
                  <font-awesome-icon icon="edit" aria-label="edit mentor" />
                </router-link>
              </div>
            </template>
          </dynamic-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import { get } from "@/composables/httpUtil";
import Button from "@/components/reusable-components/base-level-components/Button";
import { mapActions, mapGetters } from "vuex";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import Multiselect from "@vueform/multiselect";
import {isAdm, isSra} from "@/composables/tokenUtils";

export default {
  name: "MentorsRecords",
  components: { LoadingSpinner, Button, DynamicTable, Multiselect },
  data() {
    return {
      mentors: undefined,
      filterText: "",
      isLoading: false,
      loaded: false,

      paginationPage: 1,
      paginationTotalItems: 0,
      paginationTotalPages: 0,
      itemsPerPage: 25,

      practiceAreaId: "",
      employerId: "",
      mentorStatus: "",

      employers: [],

      endOflist: false,
      tableConfig: [
        {
          display: " ",
          name: "open-mentor",
          sortable: false,
          class: "",
        },
        "prefix",
        {
          display: "First Name",
          name: "firstname",
          sortable: false,
          class: "",
        },
        {
          display: "Last Name",
          name: "lastname",
          sortable: false,
          class: "",
        },
        "suffix",
        {
          display: "Email",
          name: "email",
          sortable: false,
          class: "",
        },
        {
          display: "Visible Phone",
          name: "phone",
          sortable: false,
          class: "",
        },
        {
          display: isAdm() || isSra() ? "Edit" : "",
          name: isAdm() || isSra() ? "edit" : "",
          sortable: false,
          class: "text-center",
        },
      ],

      employerSearchInput: "",
      classes: {
        container:
          "relative h-8 w-full flex items-center justify-end box-border cursor-pointer border border-steel-gray rounded bg-white text-base leading-snug outline-none",
        containerDisabled: "cursor-default bg-gray-100",
        containerOpen: "rounded-b-none",
        containerOpenTop: "rounded-t-none",
        containerActive: "ring ring-green-500 ring-opacity-30",
        singleLabel:
          "flex items-center h-full max-w-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 pr-16 box-border",
        singleLabelText:
          "overflow-ellipsis overflow-hidden block whitespace-nowrap max-w-full",
        multipleLabel:
          "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5",
        search:
          "w-full absolute inset-0 outline-none focus:ring-0 appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5",
        tags: "flex-grow flex-shrink flex flex-wrap items-center mt-1 pl-2",
        tag: "bg-light-purple bg-opacity-30  text-sm font-semibold py-0.5 pl-2 rounded mr-1 mb-1 flex items-center whitespace-nowrap",
        tagDisabled: "pr-2 opacity-50",
        tagRemove:
          "flex items-center justify-center p-1 mx-0.5 rounded-sm hover:bg-black hover:bg-opacity-10 group",
        tagRemoveIcon:
          "bg-multiselect-remove bg-center bg-no-repeat opacity-30 inline-block w-3 h-3 group-hover:opacity-60",
        tagsSearchWrapper:
          "inline-block relative mx-1 mb-1 flex-grow flex-shrink h-full",
        tagsSearch:
          "absolute inset-0 border-0 outline-none focus:ring-0 appearance-none p-0 text-base font-sans box-border w-full",
        tagsSearchCopy: "invisible whitespace-pre-wrap inline-block h-px",
        placeholder:
          "flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-gray-400",
        caret:
          "bg-multiselect-caret bg-center bg-no-repeat w-2.5 h-4 py-px box-content mr-3.5 relative opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none",
        caretOpen: "rotate-180 pointer-events-auto",
        clear:
          "pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex hover:opacity-80",
        clearIcon:
          "bg-multiselect-remove bg-center bg-no-repeat w-2.5 h-4 py-px box-content inline-block",
        spinner:
          "bg-multiselect-spinner bg-center bg-no-repeat w-4 h-4 z-10 mr-3.5 animate-spin flex-shrink-0 flex-grow-0",
        dropdown:
          "max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-steel-gray -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b",
        dropdownTop:
          "-translate-y-full top-px bottom-auto flex-col-reverse rounded-b-none rounded-t",
        dropdownHidden: "hidden",
        options: "flex flex-col p-0 m-0 list-none",
        optionsTop: "flex-col-reverse",
        group: "p-0 m-0",
        groupLabel:
          "flex text-sm box-border items-center justify-start text-left py-1 px-3 font-semibold bg-gray-200 cursor-default leading-normal",
        groupLabelPointable: "cursor-pointer",
        groupLabelPointed: "bg-gray-300 text-gray-700",
        groupLabelSelected: "bg-light-purple bg-opacity-30 ",
        groupLabelDisabled: "bg-gray-100 text-gray-300 cursor-not-allowed",
        groupLabelSelectedPointed: "bg-light-purple bg-opacity-30  opacity-90",
        groupLabelSelectedDisabled:
          "text-green-100 bg-light-purple bg-opacity-30 bg-opacity-50 cursor-not-allowed",
        groupOptions: "p-0 m-0",
        option:
          "flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3",
        optionPointed: " bg-light-purple bg-opacity-30",
        optionSelected: " bg-light-purple bg-opacity-30",
        optionDisabled: " cursor-not-allowed bg-slate-gray",
        optionSelectedPointed: " bg-light-purple bg-opacity-30 opacity-90",
        optionSelectedDisabled:
          "text-green-100 bg-light-purple bg-opacity-30 bg-opacity-50 cursor-not-allowed",
        noOptions: "py-2 px-3 text-gray-600 bg-white text-left",
        noResults: "py-2 px-3 text-gray-600 bg-white text-left",
        fakeInput:
          "bg-transparent absolute left-0 right-0 -bottom-px w-full h-px border-0 p-0 appearance-none outline-none text-transparent",
        spacer: "h-9 py-px box-content",
      },
    };
  },
  created() {
    this.paginationPage = Number(
      Object.keys(this.$route.query).includes("page")
        ? this.$route.query.page
        : this.paginationPage
    );

    this.itemsPerPage = Number(
      Object.keys(this.$route.query).includes("pageSize")
        ? this.$route.query.pageSize
        : this.itemsPerPage
    );

    Promise.all([this.fetchLookupResource(), this.getMentors()]).then(() => {
      this.loaded = true;
    });
  },
  watch: {
    paginationPage: {
      handler(newPage) {
        this.$router.push({
          path: this.$route.path,
          query: { page: newPage, pageSize: this.pageSize },
        });
      },
      immediate: false,
    },
    itemsPerPage: {
      handler(newPageSize) {
        this.$router.push({
          path: this.$route.path,
          query: { page: this.page, pageSize: newPageSize },
        });
      },
      immediate: false,
    },
    filterText: {
      handler() {
        this.getMentors();
      },
      immediate: false,
    },
    practiceAreaId: {
      handler() {
        this.getMentors();
      },
      immediate: false,
    },
    employerId: {
      handler() {
        this.getMentors();
      },
      immediate: false,
    },
    mentorStatus: {
      handler() {
        this.getMentors();
      },
      immediate: false,
    },
  },
  methods: {
    ...mapActions(["fetchLookupResource"]),
    isAdm,
    isSra,
    getMentors() {
      let url = `admin/mentors?page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      if (this.practiceAreaId) {
        url = `${url}&practice_area=${this.practiceAreaId}`;
      }
      if (this.employerId) {
        url = `${url}&employer=${this.employerId}`;
      }
      if (this.mentorStatus) {
        url = url = `${url}&status=${this.mentorStatus
          .trim()
          .replace(/\s/g, "_")
          .toUpperCase()}`;
      }
      if (this.filterText) {
        url = url = `${url}&name=${this.filterText}`;
      }

      this.isLoading = true;
      get(url)
        .then((results) => {
          this.mentors = results.data;

          this.paginationTotalItems = results.count;
          this.paginationTotalPages = results.total_pages;
        })
        .catch((error) => {
          //TODO: do something with this error and display it to the user
          console.log("error in catch statement", error);
          this.mentors = [];
        })
        .finally(() => (this.isLoading = false));
    },
    searchEmployers: async function (query) {
      let url = `admin/employers?name=${query}`;
      if (query) {
        return await get(url)
          .then((results) => {
            return results.map((item) => {
              return { value: item.employer_id, label: item.employer_name };
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        return [];
      }
    },
    selectEmployers(searchValue) {
      console.log(searchValue);
    },
    reset() {
      this.mentors = [];
      this.isLoading = false;
      this.filterText = "";
      this.page = 1;
      this.practiceAreaId = "";
      this.employerId = "";
      this.mentorStatus = "";
    },
    displayPhoneNumber(phone) {
      let phoneNumber = "";

      if (phone?.length > 0) {
        phone.forEach((number) => {
          if (number.is_public === true) {
            phoneNumber = number.phone_number + " (" + number.phone_type + ")";
          }
        });
      }
      return phoneNumber ? phoneNumber : "No Visible Phone";
    },
    nextPage(paginationInformation) {
      this.paginationPage = paginationInformation.newPage;
      this.getMentors();
    },
  },
  computed: {
    ...mapGetters(["lookup_mentor_status_options", "lookup_practice_areas"]),
    filtered() {
      return (
        this.practiceAreaId ||
        this.employerId ||
        this.mentorStatus ||
        this.filterText
      );
    },
  },
};
</script>

<style scoped>
input[type="text"] {
  @apply border-steel-gray rounded h-8;
}

select {
  @apply border-steel-gray rounded h-8 py-0;
}
</style>
